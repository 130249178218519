<template>
    <van-nav-bar title="常见问题" left-arrow fixed @click-left="goBack" />
    <div class="deerA-common-problem">
        <div class="head-search">
            <van-field
                class="search-input"
                v-model.trim="queryText"
                :clearable="true"
                left-icon="https://routine-cdn-resource.oss-cn-hangzhou.aliyuncs.com/website/images/deer-download/icon-search.png"
                placeholder="输入你遇到的问题"
            />
            <div class="search-btn" :class="{ 'can-search': queryText }" @click="searchFn('p', queryText)">搜索</div>
        </div>

        <div class="content" v-html="htmlText"></div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { showToast } from 'vant'

const router = useRouter()

// 常见问题文本
let htmlText = ref('')
htmlText.value = router.currentRoute.value.query.htmlText

// 搜索内容
const queryText = ref('')
// 上一次搜索的内容
let preQuery = ''
const searchFn = (element, query) => {
    const nodeList = document.querySelectorAll(element)
    let jumpNode = null
    // 清除上一个搜索文本样式
    if (preQuery) {
        let preReg = new RegExp('<span style="color: #FE4A77; background-color: #FFEDF2">' + preQuery + '</span>', 'g')
        nodeList.forEach(item => {
            item.innerHTML = item.innerHTML.replace(preReg, preQuery)
        })
    }
    // 为搜索文本添加样式
    if (query) {
        let reg = new RegExp(query, 'g')
        nodeList.forEach(item => {
            if (!jumpNode && reg.test(item.innerHTML)) {
                jumpNode = item
            }
            item.innerHTML = item.innerHTML.replace(
                reg,
                '<span style="color: #FE4A77; background-color: #FFEDF2">' + query + '</span>'
            )
        })
    } else {
        return showToast('请输入内容')
    }
    // 保存搜索内容并跳转
    preQuery = query
    if (jumpNode) {
        window.scrollTo({
            top: jumpNode.offsetTop - 100,
            behavior: 'smooth'
        })
    } else {
        showToast('未找到相关内容')
    }
}

const goBack = () => {
    router.go(-1)
}
</script>

<style scoped lang="scss">
.deerA-common-problem {
    padding-top: 20px;

    .head-search {
        position: sticky;
        top: 44px;
        width: 100%;
        height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fff;

        .search-input {
            display: flex;
            align-items: center;
            width: 270px;
            height: 35px;
            background: rgba(0, 0, 0, 0.05);
            border-radius: 18px;
            padding: 0 15px;
        }

        .search-btn {
            width: 65px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            background: #f2f2f2;
            border-radius: 18px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #666666;
        }

        .can-search {
            background: #e95a7e;
            color: #ffffff;
        }
    }
}
</style>

<!-- 解决v-html样式不生效问题 -->
<style lang="scss">
.deerA-common-problem {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
    padding: 0 16px;
    box-sizing: border-box;

    .content {
        padding: 10px 0 15px;

        p {
            font-size: 14px;
            font-family: PingFang SC;
            color: #000000;
            line-height: 29px;
        }

        .main-title {
            font-size: 17px;
            font-weight: 700;
            padding: 20px 0 2px;

            &:first-of-type {
                padding-top: 0;
            }
        }

        .sub-title {
            font-weight: 700;
        }

        .detail-text {
            color: #666666;
        }
    }
}
</style>
